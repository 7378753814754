import {makeStyles} from "@material-ui/styles";

export interface LeaderboardStyle {
    brokenRobotFontName: string
    alternateFontName: string

    backgroundColor: string
    purpleColor: string
    pinkColor: string
    blackColor: string
    grayColor: string

    fontPadding: number
}

const getOs = () => {
    const os = ['Windows', 'Linux', 'Mac']; // add your OS values
    return os.find(v => navigator.appVersion.indexOf(v) >= 0);
}

export const os = getOs()

export const leaderboardStyle: LeaderboardStyle = {
    brokenRobotFontName: 'brokenRobotFont',
    alternateFontName: 'Arial',

    backgroundColor: 'black',
    blackColor: 'black',
    purpleColor: 'rgb(112, 95, 235)',
    pinkColor: 'rgb(225, 68, 157)',
    grayColor: '#bbbbbc',
    fontPadding: os != 'Windows' ? 0 : 6
}

export const linkHoverStyle = makeStyles({
    root: {
        color: leaderboardStyle.purpleColor,
        fontFamily: leaderboardStyle.brokenRobotFontName,
        textDecoration: 'none',
        transition: 'color 0.25s ease-in-out',
        "&:hover": {
            color: leaderboardStyle.pinkColor
        }
    },
    hoverEffect: {
        transition: 'color 0.25s ease-in-out',
        "&:hover": { color: '#e1449d !important' }
    }
})