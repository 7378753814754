import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {
    useParams
} from "react-router-dom";

import background from "../../assets/bar/01_BurgerBarNoSky.png"
import foreground from "../../assets/bar/04_Centre_Bar.png"
import BarRenderer from "./components/BarRenderer";


const getOs = () => {
    const os = ['Windows', 'Linux', 'Mac']; // add your OS values
    return os.find(v => navigator.appVersion.indexOf(v) >= 0);
}

const os = getOs()

const BurgerBar = () => {
    let params = useParams();

    const borderRadius = 0
    const ratio = 0.99
    // const width = height * ratio
    const name = "barComposite"

    return (
        <main>
            <h1>{ params.id }</h1>

            <Box
                sx={{
                    backgroundColor: 'primary.dark',
                    width: '100%'
                }}
            >
            <div key={name + "-container"} className={ "image-stack" + " " + name }>

                {
                        <img key={'background-image'} src={ background }
                             alt=""
                             width={'100%'}
                             // height={height}
                             style={{
                                 position: "relative",
                                 float: 'left',
                                 marginRight: "-100%",
                                 borderTopLeftRadius: borderRadius,
                                 borderTopRightRadius: borderRadius
                         }}/>
                }
                {
                    <img key={'foreground-image'} src={ foreground }
                         alt=""
                         width={'100%'}
                         style={{
                             position: "relative",
                             float: 'left',
                             marginRight: "-100%",
                             borderTopLeftRadius: borderRadius,
                             borderTopRightRadius: borderRadius
                         }}/>
                }
            </div>
            </Box>


            {/*<BarRenderer images={[{ zIndex: 0, content: background }, { zIndex: 1, content: foreground }]} name={"test"} height={300} />*/}
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                            body {
                              margin: 0;
                              font-family: brokenRobotFont !important;
                            
                              /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
                              /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
                              /*  sans-serif;*/
                              -webkit-font-smoothing: antialiased;
                              -moz-osx-font-smoothing: grayscale;
                              background-color: #171432 !important;
                              background: #e1449d;
                              color: #e1449d;
                            }
                        `
                }}
            />
        </main>
    );
};

export default BurgerBar;
