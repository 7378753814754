import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Link} from "react-router-dom";
import {urls} from "../routing/external";

import burgerIcon from '../assets/burger_icon.png'
import twitterIcon from '../assets/Twitter_Pink_V1-01.png'
import discordIcon from '../assets/Discord_Pink_V2-01.png'
import {leaderboardStyle} from "../pages/leaderboard/LeaderboardStyle";
import {makeStyles} from "@material-ui/styles";

const listStyle = {
    fontSize: '40px',
        justifyContent: "center",
        color: leaderboardStyle.pinkColor,
        padding: 0,
    // ":hover &:a": {
    //     textDecoration: "underline !important"
    // },
    // hover: {
    //     ":hover": {
    //         backgroundColor: 'rgb(7, 177, 77, 0.42)'
    //     },
    // },
}

export const style = makeStyles({
    linkStyle: {
        color: leaderboardStyle.pinkColor,
        textDecoration: "none",
        justifyContent: "center",
        "&:hover": {
            textDecoration: "underline !important",
            color: leaderboardStyle.pinkColor
        }
    },
    button: {
        // backgroundColor: '#3c52b2',
        color: '#fff',
        width: 'fit-content',
        height: '75px',
        backgroundColor: '#171432 !important',
        borderRadius: '50% !important',
        borderColor: "black",
        borderWidth:"2",
        boxShadow: "(16px 16px 10px black)",
        transition: "transform .1s ease-in-out !important",
        '&:hover': {
            transform: 'scale(1.1) rotate(10deg)'
            // transition: 'all 0.3s ease-in-out 0s !important',
            // transform: 'rotate(270deg)'
            // backgroundColor: '#fff !important' ,
            // color: '#3c52b2',
            // borderRadius: '50% !important',
        }
    },
    socialButton: {
        marginRight: '10px',
        marginLeft: '10px',
        marginTop: '10px',
        transition: "transform .1s ease-in-out !important",
        '&:hover': {
            transform: 'scale(1.1)'
        }
    }
})


export default function NavigationDrawer() {
    const classes = style()

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: any, open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: any) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400, backgroundColor: "#171432", height: "100%"}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <br />
            <br />
            <br />
            <List style={{justifyContent: "center"}}>
                <ListItem style={listStyle} key={"Home"}>
                    {/*<a style={listStyle} className={classes.linkStyle} href="/homepage/index.html">HOME</a>*/}
                    <a style={listStyle} className={classes.linkStyle} href="https://brokenrobotburgerbar.com">HOME</a>
                </ListItem>

                <ListItem style={listStyle} key={"Bar Explorer"}>
                    <Link className={classes.linkStyle} to="/bar-explorer">BAR EXPLORER</Link>
                </ListItem>

                <ListItem style={listStyle} key={"Scrapyard"}>
                    <Link className={classes.linkStyle} to="/scrapyard">SCRAPYARD</Link>
                </ListItem>

                <ListItem style={listStyle} key={"Staking"}>
                    {/*<a className={classes.linkStyle} href="/staking/index.html">STAKING</a>*/}
                    <a className={classes.linkStyle} href="https://staking.brokenrobotburgerbar.com/">STAKING</a>
                </ListItem>

                <ListItem style={listStyle} key={"Socials"}>
                    <a className={classes.socialButton} href={'https://twitter.com/BrokenRobotNFT'}><img src={twitterIcon} width='45' /></a>
                    <a className={classes.socialButton} href={'https://discord.com/channels/890931886707335168/'}><img src={discordIcon} width='45' /></a>
                </ListItem>

            </List>
        </Box>
    );

    const extraBoxA = (anchor: any) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450, backgroundColor: "#171432", height: "100%"}}
            role="presentation"
        >
        </Box>
    );

    const extraBoxB = (anchor: any) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, backgroundColor: "#171432", height: "100%"}}
            role="presentation"
        >
        </Box>
    );

    const anchor = 'right'

    return (
        <div>
            <React.Fragment key={anchor}>

                <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                >
                    {extraBoxB(anchor)}
                </Drawer>

                <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                >
                    {extraBoxA(anchor)}
                </Drawer>
                <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                >
                    {list(anchor)}
                </Drawer>
                <Button className={classes.button} style={{zIndex: 200 }} onClick={toggleDrawer(anchor, true)}>
                    <img src={burgerIcon} width='65' />
                </Button>
            </React.Fragment>
        </div>
    );
}