import * as React from "react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { createTheme, ThemeProvider } from "@material-ui/core";

import { Routes, Route, Outlet, Link } from "react-router-dom";

// const Dashboard = React.lazy(() => import("./pages/Dashboard"));

import "./App.css";
import NavigationDrawer from "./components/NavigationDrawer";
import BurgerBar from "./pages/burger-bar/BurgerBar";
// import Leaderboard from "./pages/leaderboard/Leaderboard";

const Leaderboard = React.lazy(() => import("./pages/leaderboard/Leaderboard"));
const Scrapyard = React.lazy(() => import("./pages/scrapyard/Scrapyard"));

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const theme = createTheme({
    palette: {
        type: 'dark',
    },
});

const App = () => {
    const endpoint = React.useMemo(() => clusterApiUrl(network), []);

    const wallets = React.useMemo(
        () => [
            getPhantomWallet(),
            getSolflareWallet(),
        ],
        []
    );

  return (
      <ThemeProvider theme={theme}>
          <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets} autoConnect>
                  <BasicExample />
              </WalletProvider>
          </ConnectionProvider>
      </ThemeProvider>
  );
};

export default App;

function Layout() {
    return (
        <div>
            <div style={{ position: "fixed", right: 15, top: 15, zIndex: 200 }}>
                <NavigationDrawer />
            </div>
            <Outlet />
        </div>
    );
}

function Home() {
    return (
        <div>
            <h2>Home</h2>
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}


export function BasicExample() {
    return (
        <div>
            {/*<h1>Lazy Loading Example</h1>*/}

            {/*<p>*/}
            {/*    This example demonstrates how to lazily load both route elements and*/}
            {/*    even entire portions of your route hierarchy on demand. To get the full*/}
            {/*    effect of this demo, be sure to open your Network tab and watch the new*/}
            {/*    bundles load dynamically as you navigate around.*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*    The "About" page is not loaded until you click on the link. When you do,*/}
            {/*    a <code>&lt;React.Suspense fallback&gt;</code> renders while the code is*/}
            {/*    loaded via a dynamic <code>import()</code> statement. Once the code*/}
            {/*    loads, the fallback is replaced by the actual code for that page.*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*    The "Dashboard" page does the same thing, but takes it even one step*/}
            {/*    further by <em>dynamically defining additional routes</em> once the page*/}
            {/*    loads! Since React Router lets you declare your routes as*/}
            {/*    <code>&lt;Route&gt;</code> elements, you can easily define more routes*/}
            {/*    by placing an additional <code>&lt;Routes&gt;</code> element anywhere*/}
            {/*    further down the element tree. Just be sure the parent route ends with a{" "}*/}
            {/*    <code>*</code> like <code>&lt;Route path="dashboard/*"&gt;</code> in*/}
            {/*    this case.*/}
            {/*</p>*/}

            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={
                        <React.Suspense fallback={<>...</>}>
                            <Leaderboard />
                        </React.Suspense>
                    } />
                    <Route
                        // index
                        path="bar-explorer"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <Leaderboard />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="scrapyard"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <Scrapyard />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="bar/:id"
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <BurgerBar />
                            </React.Suspense>
                        }
                    />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}